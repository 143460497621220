export const getQueryStringFromProductVariationAttributes = (
  productAttributes
) => {
  return (
    "?" +
    productAttributes
      .map((attribute) => {
        return `${attribute.variationAttributeName}=${attribute.variationAttributeValue}`;
      })
      .join("&")
  );
};
